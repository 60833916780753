// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';

// import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';

// ==============================|| APP - THEME, ROUTER, LOCAL ||============================== //

const App = () => {
  return (
    <ThemeCustomization>
      <RTLLayout>
        <ScrollTop>
          <>
            <Routes />
          </>
        </ScrollTop>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
